export const routes_sias = 
[
    {
        path: 'sias', name: 'dashboard_sias',
        meta: {
            module: "mod_sia"
        },
        component: () => import('@/dash/views/dashboard/sias/index_sias.vue')
    },
    {
        path: 'sias/crear', name: 'dashboard_sias_create',
        meta: {
            editMode: false,
            module: "mod_sia"
        },
        component: () => import('@/dash/views/dashboard/sias/editor_sias.vue')
    },
    {
        path: 'sias/:id', name: 'dashboard_sias_item',
        meta: {
            module: "mod_sia"
        },
        component: () => import('@/dash/views/dashboard/sias/item_sias.vue')
    },
    {
        path: 'sias/editar/:id', name: 'dashboard_sias_edit',
        meta: {
            editMode: true,
            module: "mod_sia"
        },
        component: () => import('@/dash/views/dashboard/sias/editor_sias.vue')
    }
]