<template>
<v-container fluid fill-height>
    <v-layout align-center justify-center>
        <v-card elevation="10" tile>
            <v-card-title class="justify-center">
                <span class="title">Página no encontrada</span>
            </v-card-title>
            <v-divider />
            <v-card-text class="px-10 py-5">
                <v-row>
                    <v-col class="flex-shrink-1 flex-grow-0">
                        <v-icon :size="145" color="primary">cancel</v-icon>
                    </v-col>
                    <v-col class="flex-shrink-0 flex-grow-1">
                        <p class="display-2 primary--text font-weight-light">Error 404</p>
                        <p class="ma-0 text--primary">La página que acaba de solicitar no ha sido encontrada o tal vez haya sido eliminada.</p>
                        <p class="ma-0 text--primary">Lamentamos los inconvenientes.</p>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions class="justify-end">
                <v-btn class="px-5" depressed large dark color="primary" @click="$router.back()">
                    <v-icon>arrow_back</v-icon>
                    <span class="subtitle-2">Volver</span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-layout>
</v-container>
</template>

<script>
export default {
  metaInfo: {
    title: 'Error 404'
  }
}
</script>
