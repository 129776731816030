export const routes_pc =
    [
        {
            path: 'permisos', name: 'dashboard_permisos',
            meta: {
                module: "mod_pc"
            },
            component: () => import('@/dash/views/dashboard/permisos/index_permisos.vue')
        },
        {
            path: 'permisos/crear', name: 'dashboard_permisos_create',
            meta: {
                editMode: false,
                module: "mod_pc"
            },
            component: () => import('@/dash/views/dashboard/permisos/editor_permisos.vue')
        },
        {
            path: 'permisos/:id', name: 'dashboard_permisos_item',
            meta: {
                module: "mod_pc"
            },
            component: () => import('@/dash/views/dashboard/permisos/item_permisos.vue')
        },
        {
            path: 'permisos/editar/:id', name: 'dashboard_permisos_edit',
            meta: {
                editMode: true,
                module: "mod_pc"
            },
            component: () => import('@/dash/views/dashboard/permisos/editor_permisos.vue')
        }
    ]