<template>
    <div>
        <v-app-bar color="white" app flat>
            <v-btn v-if="$vuetify.breakpoint.mdAndDown" icon @click="show_sidebar">
                <v-icon>menu</v-icon>
            </v-btn>
            <v-icon large color="secondary" v-if="!!title.icon">{{ title.icon }}</v-icon>
            <h1 class="title font-weight-regular pa-2 window-title">{{ title.text }}</h1>
        </v-app-bar>
        <Sidebar ref="sidebar" :data="menu" title="GMACONTROL" color="primary" bg-class="sidebar-main-bg" :dark="true">
            <template v-slot:user-sub="{ user, type }">
                <span class="d-block pb-1">{{ user.cargo }}</span>
                <span class="d-block">{{ user.areas }}</span>
            </template>
        </Sidebar>
        <v-main class="ma-4">
            <router-view @title="set_title" :key="reload_count"></router-view>
        </v-main>
        <v-overlay fixed color="white" v-show="is_loading && app_indicator">
            <v-progress-circular indeterminate size="128" width="10" color="primary" />
        </v-overlay>
    </div>
</template>

<style scoped>
.sidebar-main-bg {
  background-color: rgb(47,58,76) !important;
}

.v-app-bar {
  box-shadow: 0px 1px 0px rgba(0,0,0, 0.125), 0px 2px 5px rgba(0,0,0,0.125);
}
.window-title {
  overflow: hidden;
  white-space: nowrap;
}
</style>

<script>
import Sidebar from '@/shared/components/Sidebar'
import { mapState, mapActions } from 'vuex'
import { axiosHelper } from '@/helpers'
import _menu from '@/assets/menu.json'

export default {
  metaInfo() {
    return {
      title: this.title.text
    }
  },
  components: {
    Sidebar
  },
  data: () => ({
    title: {
      text: "",
      icon: ""
    },
    menu: _menu,
    app_indicator: true
  }),
  computed: {
    ...mapState('app', ['is_loading', 'error', 'module_access']),
    ...mapState('auth', ['user', 'logged', 'reload_count', 'message']),
    margins() {
      return this.$vuetify.breakpoint.mdAndDown ? 'mx-2' : 'mx-10 mt-2';
    }
  },
  methods: {
    ...mapActions('auth', ['login_verify', 'login_refresh', 'reload']),
    reloadComponent() {
      this.reload();
    },
    set_title(title) {
      this.title = title;
    },
    disable_app_indicator() {
      this.app_indicator = false;
    },
    get_permission(mod_code, only_super=false) {
      if (this.user.groups.includes('supers')) return true;
      if (only_super || !this.module_access) return false;

      let access = this.module_access;
      return access[mod_code] || false;
    },
    show_sidebar() {
        this.$refs.sidebar.show();
    }
  },
  created() {
      this.app_indicator = true;
      this.login_verify();
  },
  watch: {
    reload: function(_new, _old) {
      console.log('reload');
    },
    logged: function(_new, _old) {
      if(_new == _old) return;

      if(!_new) {
        this.logout();
        this.$router.push({ name: 'login' });
      }
    },
    message: function(_new, _old) {
      if(_new === '') return;
      if(_new == null) return;

      if(!this.app_indicator) return;

      var errorText = _new;

      if (_new.response) {
        const error = _new.response;

        if(error.status == 401) {
          if(error.data.code === 'token_not_valid') {
            if(error.data.messages.filter(x => x.message == 'Token is invalid or expired').length > 0) {
              this.login_refresh();
            }
          }
        }
      }
    },
    error: function(_new, _old) {
      if(_new === '') return;
      if(_new == null) return;

      if(!this.app_indicator) return;

      var errorText = _new;

      if (_new.response) {
        const error = _new.response;

        if(error.status == 401) {
          if(error.data.code === 'token_not_valid') {
            if(error.data.messages.filter(x => x.message == 'Token is invalid or expired').length > 0) {
              this.login_refresh();
            }
          }
        }
      }
    }
  }
}
</script>
