import { axiosHelper } from '@/helpers'
//import router from '@/router'

const user          = JSON.parse(localStorage.getItem('user'));
const token         = localStorage.getItem('token');
const token_refresh = localStorage.getItem('token_refresh');

const state = {
    prefix: '',
    busy: false,
    refresh_busy: false,
    user: user,
    logged: token ? true : false,
    tokens: {
        access: token,
        refresh: token_refresh
    },
    reload_count: 0,
    message: null
}

const actions = {
    login_error({ commit }, message) {
        commit('login_fail', message);
    },
    login({ dispatch, commit, state }, { username, password }) {
        commit('begin_login');
        axiosHelper.post(state.prefix + 'usr/login/', { username, password })
            .then(response => {
                commit('login_success', response);
            })
            .catch(error => {
                commit('login_fail', error.response || {});
            });
    },
    login_update_user({ state }) {
        axiosHelper.get(state.prefix + 'usr/data/')
                   .then(response => {
                       state.user = response.data.usuario;
                       localStorage.setItem('user', JSON.stringify(state.user));
                   });
        state.logged = true;
        state.busy = false;
    },
    login_verify({ dispatch, state }) {
        axiosHelper.post(state.prefix + 'usr/verify/', { token: state.tokens.access })
            .then(response => {
                state.logged = true;
            })
            .catch(error => {
                //console.log('Error: refreshing token');
                dispatch('login_refresh');
            });
    },
    login_refresh({ dispatch, commit, state }) {
        state.busy = true;
        state.refresh_busy = true;
        axiosHelper.post(state.prefix + 'usr/refresh/', { "refresh": state.tokens.refresh })
            .then(response => {
                commit('login_success', response);
                state.busy = false;
                commit('reload');
            })
            .catch(error => {
                commit('login_fail', error.response || {});
                dispatch('logout');
            });
    },
    login_refresh_nonupdate({ dispatch, commit, state }) {
        state.busy = true;
        state.refresh_busy = true;
        axiosHelper.post(state.prefix + 'usr/refresh/', { "refresh": state.tokens.refresh })
            .then(response => {
                commit('login_success', response);
            })
            .catch(error => {
                state.refresh_busy = false;
                commit('login_fail', error.response || {});
                dispatch('logout');
            });
    },
    logout({ commit }) {
        commit('logout');
    },
    reload({ commit }) {
        commit('reload');
    },
}

const mutations = {
    init_app(state, response) {
        state.prefix = response == 'educap' ? 'edu/' : '';
    },
    begin_login(state) {
        state.busy = true;
        state.logged = false;
        state.message = null;
    },
    login_fail(state, error) {
        state.message = error;
        state.logged = false;
        state.busy = false;
        state.user = null;
        state.tokens.access = null;
        state.tokens.refresh = null;
    },
    login_success(state, response) {
        if (response.data) {
            if (response.data.access) {
                localStorage.setItem('token', response.data.access);
                state.tokens.access = response.data.access;
            }

            if (response.data.refresh) {
                localStorage.setItem('token_refresh', response.data.refresh);
                state.tokens.refresh = response.data.refresh;
            }

            if (response.data.user) {
                localStorage.setItem('user', JSON.stringify(response.data.user));
                state.user = response.data.user;
            }
            
            state.logged = true;
            state.busy = false;
            state.refresh_busy = false;
        }
    },
    logout(state) 
    {
        state.logged = false;
        state.busy = false;
        state.user = null;
        state.tokens.access = null;
        state.tokens.refresh = null;
        localStorage.clear();
    },
    reload(state) {
        state.reload_count++;
    }
}

export const auth = {
    namespaced: true,
    state,
    actions,
    mutations
};
