<template>
    <v-dialog v-model="visible" persistent max-width="500" transition="fade-transition">
        <v-card tile>
            <v-card-title :class="title_class">
                <span>{{ title }}</span>
            </v-card-title>
            <v-divider />
            <v-card-text class="ma-0 pa-0 text--primary">
                <v-row class="py-0 ma-0" align="center" justify="center">
                    <v-col class="flex-grow-0 flex-shrink-0">
                        <v-icon size="64" :color="color">{{ icon }}</v-icon>
                    </v-col>
                    <v-col class="flex-grow-1 flex-shrink-0" v-html="text" />
                </v-row>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn depressed large color="primary" class="subtitle-2 px-4" @click="close">Aceptar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style scoped>
.v-card__title {
    padding: 5px 10px !important;
}

.v-card__actions {
    border-top: 1px solid #eee;
    box-shadow: inset 0px 1px 0px white;
    background-color: rgb(245, 245, 245);
}
</style>

<script>
export default {
    data: () => ({
        visible: false,
        title: '',
        text: '',
        mode: ''
    }),
    computed: {
        icon() {
            if (this.mode == 'error') return 'error_outline';
            if (this.mode == 'success') return 'check_circle_outline';
            return '';
        },
        color() {
            if (this.mode == 'error') return 'red';
            if (this.mode == 'success') return 'green';
            return '';
        },
        title_class() {
            if (this.mode == '') 
                return '';
            return 'white--text ' + this.color;
        }
    },
    methods: {
        show(title, text, mode) {
            this.visible = true;
            this.text = text;
            this.title = title;
            this.mode = mode;
        },
        close() {
            this.visible = false;
            this.$emit('onClose');
        }
    }
}
</script>