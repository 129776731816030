export const routes_lv = 
[
    {
        path: 'liderazgos', name: 'dashboard_liderazgos',
        meta: {
            module: "mod_lv"
        },
        component: () => import('@/dash/views/dashboard/lv/index_liderazgos.vue')
    },
    {
        path: 'liderazgos/crear', name: 'dashboard_liderazgos_create',
        meta: {
            editMode: false, 
            module: "mod_lv"
        },
        component: () => import('@/dash/views/dashboard/lv/editor_liderazgos.vue')
    },
    {
        path: 'liderazgos/:id', name: 'dashboard_liderazgos_item',
        meta: {
            module: "mod_lv"
        },
        component: () => import('@/dash/views/dashboard/lv/item_liderazgos.vue')
    },
    {
        path: 'liderazgos/editar/:id', name: 'dashboard_liderazgos_edit',
        meta: { 
            editMode: true, 
            module: "mod_lv"
        },
        component: () => import('@/dash/views/dashboard/lv/editor_liderazgos.vue')
    }
]