import estados from '@/assets/estados'

export default {
    get_estado,
    color_estados
}

export function get_estado(tipo, int) {
    try {
        var json = estados[tipo];
        return json.filter(x => x.value == int)[0].text;
    } catch (error) {
        return "Desconocido"
    }
}

export function json_clases_incidente() {
    return ["P1", "P2", "P3"];
} 

export function color_estados(value, tipo) {
    try {
        var json = estados[tipo];
        return json.filter(x => x.value == value)[0].color;
    } catch (error) {
        return 'gray'
    }
}

export function user_isin(user_groups, dest_groups) {

    if(!dest_groups || !user_groups) {
        return false;
    }

    if(dest_groups === 'all') {
        return true;
    }
        
    if(typeof(dest_groups) === 'string') {
        dest_groups = [ dest_groups ];
    }

    return dest_groups.some(r => user_groups.includes(r));
}