export const routes_incidentes = 
[
    {
        path: 'incidentes', name: 'dashboard_incidentes',
        meta: {
            module: "mod_incidentes"
        },
        component: () => import('@/dash/views/dashboard/incidentes/index_incidentes.vue')
    },
    {
        path: 'incidentes/crear', name: 'dashboard_incidentes_create',
        meta: {
            module: "mod_incidentes",
            editMode: false 
        },
        component: () => import('@/dash/views/dashboard/incidentes/editor_incidentes.vue')
    },
    {
        path: 'incidentes/:id', name: 'dashboard_incidentes_item',
        meta: {
            module: "mod_incidentes"
        },
        component: () => import('@/dash/views/dashboard/incidentes/item_incidentes.vue')
    },
    {
        path: 'incidentes/editar/:id', name: 'dashboard_incidentes_edit',
        meta: {
            module: "mod_incidentes",
            editMode: true
        },
        component: () => import('@/dash/views/dashboard/incidentes/editor_incidentes.vue')
    }
]