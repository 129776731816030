export const routes_meteodata = 
[
    {
        path: 'aire/pronosticos', name: 'dashboard_meteodata_v1',
        meta: {
            module: "mod_meteodata"
        },
        component: () => import('@/dash/views/dashboard/meteodata/index_v1.vue')
    },
    {
        path: 'aire/nefobasimetros', name: 'dashboard_meteodata_v2',
        meta: {
            module: "mod_meteodata"
        },
        component: () => import('@/dash/views/dashboard/meteodata/index_v2.vue')
    },
    {
        path: 'aire/datos', name: 'dashboard_meteodata_v3',
        meta: {
            module: "mod_meteodata"
        },
        component: () => import('@/dash/views/dashboard/meteodata/index_v3.vue')
    },
    {
        path: 'aire/dispatch', name: 'dashboard_meteodata_v4',
        meta: {
            module: "mod_meteodata"
        },
        component: () => import('@/dash/views/dashboard/meteodata/index_v4.vue')
    }
    
]