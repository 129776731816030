export const routes_users = 
[
    {
        path: 'usuarios/mails', name: 'dashboard_config_correos',
        meta: { 
            module: "mod_users",
            groups: ['supers'] 
        },
        component: () => import('@/dash/views/dashboard/usuarios/index_correos.vue')
    },
    {
        path: 'usuarios/permisos', name: 'dashboard_config_permisos',
        meta: { 
            module: "mod_users",
            groups: ['supers'] 
        },
        component: () => import('@/dash/views/dashboard/usuarios/index_permisos.vue')
    },
    {
        path: 'usuarios/accesos', name: 'dashboard_usuarios',
        meta: { 
            module: "mod_users",
            groups: ['supers'] 
        },
        component: () => import('@/dash/views/dashboard/usuarios/index_usuarios.vue')
    },
    {
        path: 'usuarios/crear', name: 'dashboard_usuarios_create',
        meta: { 
            module: "mod_users",
            editMode: false,
            groups: ['supers'] 
        },
        component: () => import('@/dash/views/dashboard/usuarios/editor_usuarios.vue')
    },
    {
        path: 'usuarios/editar/:id', name: 'dashboard_usuarios_edit',
        meta: {
            module: "mod_users",
            editMode: true,
            groups: ['supers'] 
        },
        component: () => import('@/dash/views/dashboard/usuarios/editor_usuarios.vue')
    }
]