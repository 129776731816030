<template>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    metaInfo: {
        title: 'Cerrando sesión'
    },
    methods: {
        ...mapActions('auth', ['logout'])
    },
    mounted() {
        this.logout();
        this.$router.push({ name: 'login' });
    }
}
</script>