const state = {
    is_loading: false,
    load_count: 0,
    error_msg: null,
    module_access: {}
};

const actions = { }

const mutations = {
    set_module_data(state, data) {
        state.module_access = data;
    },
    set_loading(state, loadingState) 
    {
        if(loadingState) {
            state.load_count++;
            state.is_loading = true;
        } else if(state.load_count > 0) {
            state.load_count--;
            state.is_loading = (state.load_count > 0);
        }
    },
    error(state, text) {
        state.error_msg = text;
    }
}

export const app = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters: {
        is_loading: state => state.is_loading
    }
};
