<template>
    <v-card tile :outlined="outlined" :elevation="elevation">
        <v-card-title class="fancy-title">
            {{ title }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="px-5 py-2">
            <slot></slot>
        </v-card-text>
        <v-card-actions :class="actionsAlignClass">
            <slot name="actions"></slot>
        </v-card-actions>
    </v-card>
</template>

<style scoped>
.fancy-title {
    background-color: rgb(245, 245, 245);
    border-bottom: 1px solid white;
    text-shadow: 0px 1px 0px white;
    color: rgb(80, 80, 80);
    padding: 6px 10px !important;
}

.v-card__actions {
    border-top: 1px solid #eee;
    box-shadow: inset 0px 1px 0px white;
    background-color: rgb(245, 245, 245);
}
</style>

<script>
export default {
    props: ['title', 'actions-align', 'elevation', 'outlined'],
    computed: {
        actionsAlignClass() {
            if(this.actionsAlign == 'right') return 'justify-end';
            if(this.actionsAlign == 'center') return 'justify-center';
            return '';
        }
    }
}
</script>