import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store'
import vuetify from '@/plugins/vuetify';
import filters from '@/helpers/filters';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import { ValidationProvider } from 'vee-validate';
import Axios from 'axios'
import VueAxios from 'vue-axios'
import VueMeta from 'vue-meta'
import VueMoment from 'vue-moment'
import { Icon } from 'leaflet';
import DatetimePicker from 'vuetify-datetime-picker'

const moment = require('moment')
require('moment/locale/es')

Vue.use(VueMoment, { moment });
Vue.use(VueAxios, Axios);
Vue.use(VueMeta);
Vue.use(DatetimePicker);

filters.declare_all();

// Register it globally
// main.js or any entry file.
Vue.component('ValidationProvider', ValidationProvider);
Vue.config.productionTip = false

delete L.Icon.Default.prototype._getIconUrl
// eslint-disable-next-line  
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App),
  mounted() {
    this.$store.commit('auth/init_app', 'dash');
  }
}).$mount('#app')
