import Vue from 'vue'
import Vuex from 'vuex'
import { auth } from './auth.module'
import { app } from './app.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    app
  }
})
