import Vue from 'vue';
import { get_estado, color_estados } from './utils'

export default {
    declare_all
}

export function declare_all() 
{
    Vue.filter('string_array', string_array);
    Vue.filter('estado', estado);
    Vue.filter('color_estado', color_estados);
    Vue.filter('url_filename', url_filename);
    Vue.filter('decimal', to_decimal);
    Vue.filter('decimal_percent', to_percent_decimal);
    Vue.filter('parse_html', parse_html);
    Vue.filter('see_more', see_more);
}

function see_more(value, max = 140) {
    if(!value) return "";

    if (value.length > max) {
        return value.substring(0, max) + '...';
    }

    return value;
}

function parse_html(value) {
    if(!value) return "";
    
    return value.replace(/\n/gi, '<br/>');
}

function to_percent_decimal(value) {
    var final = 0;

    if (typeof value === "number") {
        final = value;
    } else if (isNaN(value)) {
        final = 0;
    } else if (value == null) {
        final = 0;
    }

    return Math.round((Math.ceil(final * 100) / 100) * 100) + "%";
}

function to_decimal(value) {
    var final = 0;

    if (typeof value === "number") {
        final = value;
    } else if (isNaN(value)) {
        final = 0;
    } else if (value == null) {
        final = 0;
    }

    return (Math.ceil(final * 100) / 100);
}

function url_filename(value) {
    if (!value)
        return "";

    return value.substring(value.lastIndexOf('/') + 1);
}

function string_array(value) 
{
    if (!Array.isArray(value)) {
        return value;
    }
    return value.join(', ');
}

function estado(value, tipo) {
    var final = 0;

    if (typeof value === "number") {
        final = value;
    } else if (isNaN(value)) {
        final = 0;
    } else if (value == null) {
        final = 0;
    }
    
    final = get_estado(tipo, value);
    return final;
}