import { axiosHeader } from './auth_header'
import axios from 'axios'
import store from '@/store'

function get(apiUrl, headerData, data = null) 
{
    return axios.get(process.env.VUE_APP_API_URL + apiUrl, {
        headers: { ...axiosHeader(), ...headerData },
        params: data
    });
} 

function post(apiUrl, data, headerData, options) 
{
    return axios.post(process.env.VUE_APP_API_URL + apiUrl, data, {
        headers: { ...axiosHeader(), ...headerData },
        ...options
    });
}

function put(apiUrl, data, headerData) {
    return axios.put(process.env.VUE_APP_API_URL + apiUrl, data, {
        headers: { ...axiosHeader(), ...headerData }
    });
}

function del(apiUrl, headerData) {
    return axios.delete(process.env.VUE_APP_API_URL + apiUrl, {
        headers: { ...axiosHeader(), ...headerData }
    });
}

function patch(apiUrl, data, headerData) 
{
    return axios.patch(process.env.VUE_APP_API_URL + apiUrl, data, {
        headers: { ...axiosHeader(), ...headerData }
    });
}

export const axiosHelper = {
    get,
    post,
    put,
    patch,
    del
};